<template>
  <div>
    <template>
      <p class="text-h6 secondary--text">Delegados UCP</p>
      <v-btn @click="dialogAddDelegadoUcp = true" color="primary" class="mx-3">
        Agregar nuevo delegado
      </v-btn>

      <div class="ma-3">
        <v-data-table 
          :headers="headers" 
          :items="listaDelegados.data" 
          :items-per-page="-1">

          <template v-slot:item.email="{ item }">
            {{ item.Usuario.email }}
          </template>

          <template v-slot:item.id_institucion="{ item }">
            <div style="max-width: 100%; overflow-wrap: break-word;">
                <v-chip-group v-if="item.DelegadoDistritos && item.DelegadoDistritos.length > 0">
                    <v-chip v-for="(distrito, index) in item.DelegadoDistritos" :key="index">
                        {{ distrito.Institucion.nombre }}
                        <v-icon @click.stop="deleteRegistroDistritoDelegado(distrito.id)">mdi-close-circle</v-icon>
                    </v-chip>
                </v-chip-group>
                <span v-else>No hay instituciones asignadas</span>
            </div>
          </template>

          <template v-slot:item.created_at="{ item }">
						{{ moment(item.created_at).format("DD/MM/YYYY hh:mm A") }}
					</template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn text @click.stop="openAdjunto(item)">
              <v-icon>mdi-paperclip</v-icon>
            </v-btn>
            <v-btn text @click="openModalDistritos(item)">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn text @click="confirmarEliminacionDelegado(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </template>

    <!-- Modal para ingresar el delegado -->
    <v-dialog v-model="dialogAddDelegadoUcp" width="800" persistent>
      <v-card class="pa-8">
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="cerrarModal">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Agregar un nuevo delegado por UCP
            </p>
          </v-col>
          <v-form ref="formBaja" lazy-validation>
            <v-col cols="12">
              <v-autocomplete
                :items="mappedUnidadesUsuarios"
                :loading="id_usuario_delegado.isLoading"
                item-text="email" 
                item-value="id" 
                label="Técnico delegado" 
                outlined 
                v-model="form.id_usuario_delegado"
                :error-messages="unidadesUsuariosErrors"
                @change="$v.form.id_usuario_delegado.$touch()"
                @blur="$v.form.id_usuario_delegado.$touch()"
                />
            </v-col>

            <v-col cols="12">
              <v-file-input 
                v-model="form.adjunto" 
                label="PDF" 
                placeholder="Seleccione un PDF" 
                outlined 
                light
                :error-messages="adjuntoErrors"
                @change="$v.form.adjunto.$touch()" 
                @blur="$v.form.adjunto.$touch()"
                accept="application/pdf" />
            </v-col>

            <!-- Botones de accion -->
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" block @click="cerrarModal">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="secondary" class="white--text text-capitalize" :disabled="botonDesactivado" @click="crearRegistro">
                  Registrar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- Modal para ingresar el delegado en conjunto con el distrito -->
    <v-dialog v-model="abrirModalDelegadoDistrito" width="900" persistent>
      <v-card class="pa-8">
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="cerrarModalDelegadoDistrito">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Asociar al delegado:
            </p>
            <p class="font-museo-sans mb-0 secondary--text text-center">
              {{ itemSeleccionado && itemSeleccionado.Usuario ? itemSeleccionado.Usuario.email : '' }}
            </p>
          </v-col>
          <v-form>
            <v-col cols="12">
              <v-text-field
                item-text="nombre" 
                item-value="id" 
                label="Búsqueda de distrito" 
                :loading="id_institucion_distrito.isLoading"
                outlined
                v-model="filtroDistrito"
                @input="debouncedSearch"
                />
            </v-col>
            <v-row class="mx-sm-6">
              <v-col cols="12">
                <div class="scroll-table">
                  <v-data-table
                    :items="mappedInstitucionDistrito"
                    hide-default-footer
                    item-text="nombre" 
                    item-value="id" 
                    :items-per-page="-1"
                    class="text-center"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombre }}</td>
                        <td>
                          <v-btn @click="agregarDistrito(item)" rounded text>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationModalComponent 
    :isOpen="modalConfirmacionEliminar" 
    description="¿Desea continuar con la eliminación del registro?"
    @confirm="deleteRegistro" 
    @cancel="modalConfirmacionEliminar = false" 
    :isLoading="deleteAction.isLoading" 
    />
    <PdfModal
			:isOpen="modalVisualizacionAdjuntoAbierta" 
			:source-loadable="visualizacionAdjunto" 
			@on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
			filename="visualizacion-delegado"
		/>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from '@/utils/loadable.js';
import { convertToFormData } from '@/utils/data';
import { debounce } from 'lodash';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import PdfModal from '@/components/PdfModal.vue';

export default {
  name: "indexDelegadosUcp",
  components: { ConfirmationModalComponent, PdfModal },
  validations: {
    form: {
        id_usuario_delegado: { required },
        adjunto: { required },
    },
  },
  data: () => ({
    id_usuario_delegado: createLoadable([]),
    id_institucion_distrito: createLoadable([]),
    deleteAction: createLoadable(null),
    deleteActionDistrito: createLoadable(null),
    modalConfirmacionEliminar: null,
    modalConfirmacionEliminarDistritoDelegado: null,
    mappedUnidadesUsuarios: [],
    mappedInstitucionDistrito: [],
    dialogAddDelegadoUcp: false,
    listaDelegados: [],
    abrirModalDelegadoDistrito: false,
    itemSeleccionado: null,
    filtroDistrito: null,
    botonDesactivado: false,
    debouncedSearch: null,
    //visualizar Adjunto
		visualizacionAdjunto: createLoadable(null),
		modalVerAdjunto: false,
		modalVisualizacionAdjuntoAbierta: false,
      headers: [
        {
          text: "Correo",
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: "Asignaciones",
          value: "id_institucion",
          align: "center",
          sortable: false,
        },
        {
          text: "Delegado desde",
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],
    form: {
      id_usuario_delegado: null,
      adjunto: null,
    },
  }),
  computed: {
    unidadesUsuariosErrors() {
      const errors = [];
      if (!this.$v.form.id_usuario_delegado.$dirty) return errors;
      !this.$v.form.id_usuario_delegado.required &&
        errors.push("El usuario debe ser obligatorio");
      return errors;
    },
    adjuntoErrors() {
      const errors = [];
      if (!this.$v.form.adjunto.$dirty) return errors;
      !this.$v.form.adjunto.required &&
        errors.push("El usuario debe ser obligatorio");
      return errors;
    },
  },
  methods: {
    cerrarModal() {
      this.dialogAddDelegadoUcp = false;
      this.form.id_usuario_delegado = null;
      this.form.adjunto= null;
      this.listarDelegadosUcp();
    },
    cerrarModalDelegadoDistrito() {
      this.abrirModalDelegadoDistrito = false;
      this.filtroDistrito = null,
      this.listarDelegadosUcp();
      this.listarInstitucionesDistrito();
    },
    async openModalDistritos(item) {
      this.abrirModalDelegadoDistrito = true;
      this.itemSeleccionado = item;
    },
    async listarUsuariosDeUnidad() {
      toggleLoadable(this.id_usuario_delegado)
      const { data } = await this.services.DelegadosUCp.getListaUsuariosUnidad();
      this.mappedUnidadesUsuarios = data.map(item => ({
        id: item.empleado.usuario.id,
        email: item.empleado.usuario.email,
      }));
      setLoadableResponse(this.id_usuario_delegado, data);
    },
    async listarInstitucionesDistrito() {
      toggleLoadable(this.id_institucion_distrito)
      const { data } = await this.services.DelegadosUCp.getListaInstitucionesDistrito({ nombre: this.filtroDistrito});
      this.mappedInstitucionDistrito = data.map(item => ({
        id: item.id,
        nombre: item.nombre,
      }));
      setLoadableResponse(this.id_institucion_distrito, data);
    },
    async listarDelegadosUcp() {
      toggleLoadable(this.listaDelegados);
        const { data } = await this.services.DelegadosUCp.getListaDelegados();
        this.listaDelegados = data;
        setLoadableResponse(this.listaDelegados, data);
    },

    async crearRegistro() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.botonDesactivado = true;
        const formData = convertToFormData({ ...this.form });
        const {data, status} = await this.services.DelegadosUCp.crearDelegadoUcp(formData);
      
        if (status === 200) {
            this.temporalAlert({
              message: data.message,
              show: true,
              type: "success",
            });
        }
        else {
          this.temporalAlert({
            show: true,
            message: data.error.message,
            type: "error",
          });
        }
        this.botonDesactivado = false;
        this.cerrarModal();
      }
    },
    async agregarDistrito(institucionSelected) {
      const formData = convertToFormData({institucionSelected});
      const idUsuarioDelegado = this.itemSeleccionado.id;
      const {data, status} = await this.services.DelegadosUCp.crearDelegadoDistrito(idUsuarioDelegado, formData);

      if (status === 200) {
        this.temporalAlert({
          message: data.message,
            show: true,
            type: "success",
        });
      }
      else {
        this.temporalAlert({
          show: true,
          message: data.error.message,
          type: "error",
        });
      }
      this.listarInstitucionesDistrito();
    },
    confirmarEliminacionDelegado(item) {
      this.modalConfirmacionEliminar = true;
      this.itemSeleccionado = item;
    },
    async deleteRegistro() {
      const idUsuarioDelegado = this.itemSeleccionado.id;
      toggleLoadable(this.deleteAction);
      const { data } = await this.services.DelegadosUCp.deleteDelegadoUcp(idUsuarioDelegado);
      setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminar = false;
      this.listarDelegadosUcp();
    },
    async deleteRegistroDistritoDelegado(distrito) {
      const id_delegado_distrito = distrito;
      toggleLoadable(this.deleteActionDistrito);
      const { data } = await this.services.DelegadosUCp.deleteDelegadoDistritoUcp(id_delegado_distrito);
      setLoadableResponse(this.deleteActionDistrito, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminarDistritoDelegado = false;
      this.listarDelegadosUcp();
    },
    manejarVisibilidadModalVisualizacionAdjunto(visible) {
			this.modalVisualizacionAdjuntoAbierta = visible;
		},
		async openAdjunto(item) {
			this.modalVisualizacionAdjuntoAbierta = true;
			const idDelegado = item.id;
			toggleLoadable(this.visualizacionAdjunto);
			const { data } = await this.services.DelegadosUCp.visualizarAdjuntoDelegado(idDelegado);
			setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
		},
  },
  async created() {
    await this.listarUsuariosDeUnidad();
    await this.listarDelegadosUcp();
    await this.listarInstitucionesDistrito();

    this.debouncedSearch = debounce(this.listarInstitucionesDistrito, 600);
  }
}
</script>

<style>
.scroll-table {
  max-height: 300px; /* ajusta la altura máxima según sea necesario */
  overflow-y: auto;
}
</style>

